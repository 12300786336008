$btn-txt-color: #000000;
$btn-bg-color: $fifth-color;
$btn-active-txt-color: $fifth-color;
$btn-active-bg-color: $first-color;


$btn-icon-width: 45px;
$btn-icon-height: 45px;

$icon-projets-width: 38px;
$icon-projets-height: 44px;

$icon-yesno-width: 36px;
$icon-yesno-height: 25px;

$icon-big-width: 40px;
$icon-big-height: 40px;

$icon-confirmation-width: 100px;
$icon-confirmation-height: 80px;
