// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Global
$global-radius: 2px;
$global-padding: 20px;
$global-weight: 500;

// Font
// $webfont: yes;
// $font-family: "OpenSans";
$font-family: 'Open Sans', sans-serif;
$font-spacing: 0;
$font-icon: linear;

$h2-weight: 300;

// General
$first-color: #006a53;
$second-color: #c8d400;
$third-color: #cb521c;
$fourth-color: #cdcdcd;
$fifth-color: #ffffff;
$sixth-color: #585858;

$groupama-color: #006b52;
// Text
$first-txt-color: #3c3c3c;
$second-txt-color: #fff;


// Notification
$alert-error-color: #c52f2f;
$alert-info-color: #2fadc5;

// Field
$field-margin: 5px;

.flex-1 {
  @include flex(1 0 100%);
}
.full-width {
  width: 100%;
  @include flex(1);
}
.full-height {
  height: 100%;
  .flex-0 { @include flex(0); }
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}


// Flex
.scroll-auto { overflow-y: auto; }

.text-center {text-align: center;}
.text-right {text-align: right;}
.text-left {text-align: left;}
