input, textarea, select {
  font-family: $font-family;
  border-radius: $form-input-radius;
  border-width: $form-input-border-width;
  border-style: $form-input-border-style;
  border-color: $form-input-border-color;
  background-color: $form-input-bg-color;
  padding: $form-input-padding;
  font-size: $form-input-size;
  transition: all 0.2s ease;
  width: 100%;
  line-height: 1;
  color: $first-txt-color;
  &:focus {
    border-width: $form-input-focus-border-width;
    border-style: $form-input-focus-border-style;
    border-color: $form-input-focus-border-color;
    outline: none;
  }
  &:disabled {
    background-color: $form-input-disabled-bg-color;
    color: $form-input-disabled-color;
  }
  &::-webkit-input-placeholder { color: $form-input-placeholder-color; }
  &::-moz-placeholder { color: $form-input-placeholder-color; }
  &:-ms-input-placeholder { color: $form-input-placeholder-color; }
  &:-moz-placeholder { color: $form-input-placeholder-color; }
}

.col {
  .col {
    position: relative;
    padding-bottom: 0;
    input {
      padding: 20px 14px 8px;
      &:focus, &.filled {
        ~ label {
          font-size: 11px;
          top: 8px;
        }
      }
      + label {
        font-size: $form-input-size;
        pointer-events: none;
        position: absolute;
        top: 16px;
        left: 24px;
        transition: all .2s;
      }
    }
  }
}
