a, button {
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  color: $first-color;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: $font-family;
  position: relative;
  &:focus {
    outline: none;
  }
}


.btn {
  @extend %flexbox;
  @include align-items(center);
  .span {
    font-size: 16px;
    text-align: center;
  }
  i.arrow {
    width: 10px;
    height: 10px;
    background-image: url("../images/icons-arrows.png");
    background-repeat: none;
    &.left {
      background-position: 0 0;
      + span {
        margin-left: 12px;
        @include media-breakpoint-up(sm) {
          margin-left: 24px;
        }
      }
    }
    &.right {
      order: 1;
      background-position: 10px 0;
      + span {
        margin-right: 12px;
        @include media-breakpoint-up(sm) {
          margin-right: 24px;
        }
      }
    }
  }
  &.btn-primary {
    @extend %flexbox;
    @include justify-content(center);
    @include flex(1);
    border-radius: 10px;
    border: 1px solid $btn-txt-color;
    color: $btn-txt-color;
    padding: 10px;
    min-height: 50px;
    &.selected {
      color: $btn-active-txt-color;
      background-color: $btn-active-bg-color;
      border: 1px solid $btn-active-bg-color;
    }
    &.icon {
      min-height: 60px;
      i {
        margin-right: 8px;
      }
    }
    &.icon-left {
      @include justify-content(flex-start);
    }
    &.icon-top {
      @include flex-direction(column);
      i {
        margin-right: 0;
      }
      span {
        @extend %flexbox;
        @include align-items(center);
        @include flex(1 0 auto);
        text-align: center;
        max-width: 100%;
      }
    }
    &.yesno {
      min-height: auto;
    }
    &.fullHeight {
      @include align-items(center);
      height: 100%;
    }
  }
  &.btn-secondary {
    background-color: $third-color;
    font-weight: bold;
    padding: 6px 20px;
    border: none;
    color: #fff;
    border-radius: 5px;

    &.btn-small {
      font-weight: normal;
      padding: 6px 10px;
      @include media-breakpoint-up(sm) {
        padding: 10px 20px;
      }
      span {
        font-size: 15px;
        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }
      }
    }
    i.icon {
      width: $icon-big-width;
      height: $icon-big-height;
      background-image: url("../images/sprite-big.png");
      background-repeat: none;
      &.calendar { background-position: #{$icon-big-width * 0} #{$icon-big-height * 0}; }
      + span {
        margin-left: 16px;
      }
    }

  }
}

