.field {
  @extend %flexbox;
  @include flex-direction(column);
  @include flex-wrap(wrap);
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
  }
  &.noPadding {
    .question-wrapper {
      padding: 0;
    }
  }
  &.inline {
    @include flex-direction(row);
    @include align-items(center);
    .question-title {
      @include media-breakpoint-up(md) {
        @include flex(none);
        margin-bottom: 0;
      }
    }
    .question-wrapper {
      @include media-breakpoint-up(md) {
        padding: 0 100px 0 30px;
        @include flex(1 0 auto);
      }

      [class*="grid-"] {
        @include justify-content(flex-start);
      }
    }
    &.radio-yesno .question-wrapper {
      padding: 0;
      @include media-breakpoint-up(md) {
        padding: 0 0 0 30px;
        .col {
          @include flex(0 0 60px);
        }
      }
    }
  }
  &.fullWidthQuestion {
    padding-top: 40px;
    margin-bottom: 30px;
    .question-wrapper {
      padding: 0;
    }
    .fieldText {
      .col > div {
        width: 100%;
        text-align: center;
      }
    }
  }
  .question-wrapper {
    @include flex(1 1 auto);
    .question-title {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 6px;
    }
  }
  .question-title {
    @include flex(1 1 100%);
  }
  > .question-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 14px;
    z-index: 1;
    padding: 6px 0 14px;
    @include media-breakpoint-down(sm) {
      position: sticky;
      top: 0;
      @include gradient-orientation(vertical, #fff, rgba(#fff, 0), 60%, 100%);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 36px;
    }
  }
  > .question-wrapper {
    padding: 0 20px;
    @include media-breakpoint-up(sm) {
      padding: 0 60px;
    }
    @include media-breakpoint-up(md) {
      padding: 0 100px;
    }
  }
  &.checkbox {
    @extend %flexbox;
    @include flex-wrap(wrap);
  }
}

.column {
  .question-title {
    @include flex(none);
  }
}

.col.precision {
  .col {
    @include media-breakpoint-down(md) {
      input {
        padding: 14px;
        &:focus,
        &.filled {
          ~ label {
            font-size: 15px;
          }
        }
        & + label {
          position: static;
          order: -1;
          @include flex(1 1 100%);
          margin-bottom: 6px;
          color: $btn-txt-color;
        }
      }
    }
  }
}
