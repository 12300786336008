nav {
  @extend %flexbox;
  @include flex-direction(column);
  position: sticky;
  top: 0;
  @include media-breakpoint-up(sm) {
    @include flex-direction(row);
    @include justify-content(space-between);
  }

  .step {
    @extend %flexbox;
    @include flex(1 0 auto);
    position: relative;
    padding-bottom: 8px;
    @include media-breakpoint-up(sm) {
      @include flex-direction(row);
      padding-bottom: 0;
      padding-right: 80px
    }
    &:last-child {
      @include flex(none);
      padding-bottom: 0;
      @include media-breakpoint-up(sm) {
        padding-right: 0;
      }
    }
    button {
      @extend %flexbox;
      @include align-items(center);
      padding: 0;
      z-index: 2;
      @include media-breakpoint-up(sm) {
        @include flex-direction(column);
      }
      &.router-link-active {
        i {
          background-color: $nav-active-color;
          background-position: #{$nav-btn-width * 0} #{$nav-btn-height * 0};
        }
        //span { color: darken($nav-active-color, 10%); }
        span { color: $nav-achieve-color; }
        + .line { background-color: $nav-active-color; }
      }
      i {
        width: $nav-btn-width;
        height: $nav-btn-height;
        border-radius: 100%;
        background-image: url("../images/sprite-nav.png");
        background-position: #{$nav-btn-width * -2} #{$nav-btn-height * 0};
        background-color: $nav-inactive-color;
        margin-right: 14px;
        @include media-breakpoint-up(sm) {
          margin-right: 0;
        }
      }
      span {
        font-style: italic;
        font-size: 14px;
        color: $nav-text-color;
        white-space: nowrap;
        @include media-breakpoint-up(sm) {
          position: absolute;
          top: ($nav-btn-width + 6px);
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
    }
    .line {
      background-color: $nav-inactive-color;
      width: 1px;
      height: 100%;
      position: absolute;
      top: $nav-btn-width;
      left: calc(#{$nav-btn-width} / 2);

      @include media-breakpoint-up(sm) {
        width: calc(100% - #{$nav-btn-width});
        height: 1px;
        position: absolute;
        top: calc(#{$nav-btn-width} / 2);
        left: #{$nav-btn-width};
      }
    }

    &.validated {
      i {
        background-color: $nav-achieve-color;
        background-position: #{$nav-btn-width * -1} #{$nav-btn-height * 0};
      }
      button.router-link-active i {

        background-position: #{$nav-btn-width * -1} #{$nav-btn-height * 0};
      }
      span { color: $nav-achieve-color; }
      .line { background-color: $nav-achieve-color; }
    }
    &.confirmation, &.completer {
      button.router-link-active i {

        background-color: $nav-achieve-color;
        background-position: #{$nav-btn-width * -1} #{$nav-btn-height * 0};
      }
    }
  }
}
