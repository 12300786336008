.btn.icon  {
  i {
    width: $btn-icon-width;
    height: $btn-icon-height;
    background-image: url("../images/sprite-icons.png");

    // Icons Embarcations
    &.bateau-moteur { background-position: #{$btn-icon-width * 0} #{$btn-icon-height * 0}; }
    &.voilier { background-position: #{$btn-icon-width * -1} #{$btn-icon-height * 0}; }
    &.kayak { background-position: #{$btn-icon-width * -2} #{$btn-icon-height * 0}; }
    &.jet-ski { background-position: #{$btn-icon-width * -3} #{$btn-icon-height * 0}; }
    &.canoe { background-position: #{$btn-icon-width * -4} #{$btn-icon-height * 0}; }
    &.pedalo { background-position: #{$btn-icon-width * -5} #{$btn-icon-height * 0}; }
    &.planche-a-voile { background-position: #{$btn-icon-width * -6} #{$btn-icon-height * 0}; }
    &.autre-embarcation { background-position: #{$btn-icon-width * -7} #{$btn-icon-height * 0}; }

    // Icons Transports
    &.velo-electrique { background-position: #{$btn-icon-width * 0} #{$btn-icon-height * -2}; }
    &.trottinette-electrique { background-position: #{$btn-icon-width * -1} #{$btn-icon-height * -2}; }
    &.hoverboard { background-position: #{$btn-icon-width * -2} #{$btn-icon-height * -2}; }
    &.autre-transport { background-position: #{$btn-icon-width * -3} #{$btn-icon-height * -2}; }

    // Icons Projets
    &.demenagement { background-position: #{$btn-icon-width * 0} #{$btn-icon-height * -4}; }
    &.travaux-maison { background-position: #{$btn-icon-width * -1} #{$btn-icon-height * -4}; }
    &.voyage { background-position: #{$btn-icon-width * -2} #{$btn-icon-height * -4}; }
    &.voiture { background-position: #{$btn-icon-width * -3} #{$btn-icon-height * -4}; }
    &.mariage { background-position: #{$btn-icon-width * -4} #{$btn-icon-height * -4}; }
    &.autre-projet { background-position: #{$btn-icon-width * -5} #{$btn-icon-height * -4}; }
  }
  &.selected {
    i {
      // Icons Embarcations - Selected
      &.bateau-moteur { background-position: #{$btn-icon-width * 0} #{$btn-icon-height * -1}; }
      &.voilier { background-position: #{$btn-icon-width * -1} #{$btn-icon-height * -1}; }
      &.kayak { background-position: #{$btn-icon-width * -2} #{$btn-icon-height * -1}; }
      &.jet-ski { background-position: #{$btn-icon-width * -3} #{$btn-icon-height * -1}; }
      &.canoe { background-position: #{$btn-icon-width * -4} #{$btn-icon-height * -1}; }
      &.pedalo { background-position: #{$btn-icon-width * -5} #{$btn-icon-height * -1}; }
      &.planche-a-voile { background-position: #{$btn-icon-width * -6} #{$btn-icon-height * -1}; }
      &.autre-embarcation { background-position: #{$btn-icon-width * -7} #{$btn-icon-height * -1}; }

      // Icons Transports - Selected
      &.velo-electrique { background-position: #{$btn-icon-width * 0} #{$btn-icon-height * -3}; }
      &.trottinette-electrique { background-position: #{$btn-icon-width * -1} #{$btn-icon-height * -3}; }
      &.hoverboard { background-position: #{$btn-icon-width * -2} #{$btn-icon-height * -3}; }
      &.autre-transport { background-position: #{$btn-icon-width * -3} #{$btn-icon-height * -3}; }

      // Icons Projets - Selected
      &.demenagement { background-position: #{$btn-icon-width * 0} #{$btn-icon-height * -5}; }
      &.travaux-maison { background-position: #{$btn-icon-width * -1} #{$btn-icon-height * -5}; }
      &.voyage { background-position: #{$btn-icon-width * -2} #{$btn-icon-height * -5}; }
      &.voiture { background-position: #{$btn-icon-width * -3} #{$btn-icon-height * -5}; }
      &.mariage { background-position: #{$btn-icon-width * -4} #{$btn-icon-height * -5}; }
      &.autre-projet { background-position: #{$btn-icon-width * -5} #{$btn-icon-height * -5}; }
    }
  }

  // Icons checkbox yes/no
  &.yesno {
    i {
      width: $icon-yesno-width;
      height: $icon-yesno-height;
      background-image: url("../images/sprite-yesno.png");
      &.oui { background-position: #{$icon-yesno-width * 0} #{$icon-yesno-height * 0}; }
      &.non { background-position: #{$icon-yesno-width * -1} #{$icon-yesno-height * 0}; }
    }
    &.selected {
      i {
        &.oui { background-position: #{$icon-yesno-width * 0} #{$icon-yesno-height * -1}; }
        &.non { background-position: #{$icon-yesno-width * -1} #{$icon-yesno-height * -1}; }
      }
    }
  }
}

i.icon-confirmation {
  display: inline-block;
  width: $icon-confirmation-width;
  height: $icon-confirmation-height;
  background-image: url("../images/sprite-confirmation.png");
  &.check { background-position: #{$icon-confirmation-width * 0} #{$icon-confirmation-height * 0}; }
  &.wait { background-position: #{$icon-confirmation-width * -1} #{$icon-confirmation-height * 0}; }
}

