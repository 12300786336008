.error {
  order: 1;
  font-size: 14px;
  color: #f53242;
  .error-message {
    padding: 4px 10px 0;
  }
  &.error-date {
    font-size: 13px;
    padding: 3px 5px 0;
  }
  + .col {
    input {
      border-color: #f53242;
      color: #f53242;
      background-color: rgba(#f53242, .1);
    }
    label { color: #f53242; }
  }
}

.inline {
  .error-date {
    @extend %flexbox;
    @include align-items(center);
    height: 100%;
  }
}
