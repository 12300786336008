header {
  background-color: $groupama-color;
  padding: 10px 0;
  .logo {
    background-image: url('../images/logo-groupama.png');
    background-repeat: no-repeat;
    width: 164px;
    height: 48px;
    display: inline-block;
  }
}

.subheader {
  .container {
    @extend %flexbox;
    @include flex-direction(column);
    @include align-items(center);
  }
  margin-bottom: 20px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 36px;
  }
}
.subheader-title {
  color: $first-color;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 38px;
  margin-bottom: 20px;
  max-width: 100%;
  @include media-breakpoint-up(sm) {
    margin-bottom: 74px;
  }
}
