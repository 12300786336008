  // Field
  $form-field-margin: 0 0 14px 0;
  // Label
  $form-label-size: 14px;
  $form-label-weight: 600;
  $form-label-margin: 0 0 8px 0;
  $form-label-focus-color: lighten($first-color, 8%);
  // Input, Textarea, Select, etc...
  $form-input-size: 15px;
  $form-input-radius: 10px;
  $form-input-border-width: 1px;
  $form-input-border-style: solid;
  $form-input-border-color: $first-txt-color;
  $form-input-bg-color: #fff;
  $form-input-focus-border-width: $form-input-border-width;
  $form-input-focus-border-style: $form-input-border-style;
  $form-input-focus-border-color: $form-input-border-color;
  $form-input-disabled-bg-color: rgba(#000, .1);
  $form-input-disabled-color: rgba(#000, .5);
  $form-input-placeholder-color: $first-txt-color;
  $form-input-padding-vertical: 10px;
  $form-input-padding-horizontal: 12px;
  $form-input-padding: $form-input-padding-vertical $form-input-padding-horizontal;
  $form-textarea-min-height:  80px;
  $form-big-textarea-min-height: 140px;
  $form-big-textarea-line-height: 20px;
