* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $first-txt-color;
}

.container {
  margin: 0 20px;
  @include flex(1 0 auto);
  @include media-breakpoint-up(md) {
    margin: 0 auto;
    width: 840px;
  }
  @include media-breakpoint-up(lg) {
    width: 960px;
  }
}

img {
  max-width: 100%;
}

[class*="col"] {
  &.right {
    @include justify-content(flex-end);
  }
}
.col {
  &.column {
    @include flex-direction(column);
  }
  &.col-fullWidth {
    @include flex(1 1 100%);
    max-width: 100%;
  }
  &.col-toEnd {
    order: 1;
  }
  &.precision {
    order: unset;
    @include media-breakpoint-up(md) { order: 1; }
  }
  &.col-month {
    @include flex(0 0 90px);
  }
  &.col-year {
    @include flex(0 0 110px);
  }
}
